import { useRouter } from 'next/router';

import { Spinner } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

import { useUser } from '../contexts/Auth';

const Home = (): React.ReactElement => {
  const { featureFlags, user, isLoading } = useUser();
  const router = useRouter();

  if (isLoading) {
    return <Spinner xl m="auto" mt="20px" />;
  }

  const showTransacations =
    featureFlags.show_closings ||
    featureFlags.show_trade_ins ||
    featureFlags.show_cash_offers;

  // It's logged, then we're going to redirect the user
  if (router.query.return_url) {
    router.replace(`${router.query.return_url}`);
  } else if (featureFlags.show_portal_dashboard) {
    router.replace('/dashboard');
  } else if (user?.coordinator && showTransacations) {
    router.replace('/transactions/page/1');
  } else if (featureFlags.show_referrals) {
    router.replace('/referrals/page/1');
  } else if (showTransacations) {
    router.replace('/transactions/page/1');
  } else {
    router.replace(`${config.homelightHost}/agent-portal`);
  }

  return <Spinner xl m="auto" mt="20px" />;
};

export default Home;
